import React from "react"
import Mainsection, { MainsectionText } from "src/components/section/main"
import FeatureBoxItem from "src/components/featureBoxItem"
import Spacer from "src/components/spacer"
import Plan1Img from "src/images/plan_unit_1@2x.png"
import Plan2Img from "src/images/plan_unit_2@2x.png"
import Plan3Img from "src/images/plan_unit_3@2x.png"

const Unit = () => (
  <Mainsection title="配信ユニットのご利用について">
    <MainsectionText>
      <p>natadeCOCOスポットでコンテンツ配信するには、配信ユニットのお申し込みが必要です。</p>
    </MainsectionText>

    <Spacer isHalf />

    <div className="box is-shadowless">
      <div className="columns has-text-centered">
        <FeatureBoxItem
          title={<span>１配信ユニットで<br />１スポット</span>}
          description={<span>natadeCOCOスポット１カ所につき<br />配信ユニットが１つ必要です</span>}
          imgSrc={Plan1Img}
          alt="１配信ユニットで１スポット"
        />
        <FeatureBoxItem
          title={<span>配信ユニットは<br />複数使用OK</span>}
          description={<span>用途に応じて複数ユニット<br />ご利用いただけます</span>}
          imgSrc={Plan2Img}
          alt="配信ユニットは複数使用OK"
        />
        <FeatureBoxItem
          title={<span>使った分だけ<br />お支払い</span>}
          description={<span>スポット数×利用日数の<br />従量課金制です</span>}
          imgSrc={Plan3Img}
          alt="使った分だけお支払い"
        />
      </div>
    </div>
  </Mainsection>
)

export default Unit
