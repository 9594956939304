import React from "react"
import Styles from "./plan.module.scss"
import Mainsection, { MainsectionText } from "src/components/section/main"
import { Link } from "gatsby"
import Spacer from "src/components/spacer"
import FeatureBox from "src/components/featureBox"
import IconUnit from "src/components/IconUnit"
import ColorBox from "src/components/ColorBox"
import Wrap from "src/components/wrap"

//////////////////// components ////////////////////

const MenuItem = ({ type }) => {
  const isCloud = type === 'cloud'
  const titleJp = isCloud ? "クラウド版" : "エッジ版"
  const titleEn = isCloud ? "CLOUD TYPE" : "EDGE TYPE"
  const icon = isCloud ? "fas fa-cloud" : "fas fa-server"
  const linkTo = `#price_${type}`

  return (
    <a className={`box has-background-info ${Styles.menuItem}`} href={linkTo}>
      <div className="columns is-mobile is-vcentered">
        <div className="column is-narrow has-text-info"><IconUnit size="is-size-6" type={type} icon={icon} /></div>
        <div className={`column has-text-weight-bold ${Styles.menuItemTitles}`}>
          <p className="is-size-4 is-size-5-mobile">{titleJp}</p>
          <p className="is-size-6 is-size-7-mobile">{titleEn}</p>
        </div>
      </div>
    </a>
  )
}

const PlanItem = ({ children }) => (
  <div className="column is-one-third is-flex" style={{ flexDirection: "column" }}>
    {children}
  </div>
)

const PlanHeader = ({ planName, copy, color }) => (
  <div className={Styles.planHeader}>
    <div className={Styles.decotitle} style={{ color: color }}><span>{copy}</span></div>
    <ColorBox color={color} className="has-text-white has-text-weight-bold">
      <p className="is-size-5 is-size-6-mobile">{planName}</p>
      <p className="is-size-7">プラン</p>
    </ColorBox>
  </div>
)

const PlanBody = ({ children }) => (
  <div className={`content has-text-centered ${Styles.planBody}`}>
    {children}
  </div>
)

const Amount = ({ amount }) => (
  <div className={Styles.amount}>
    <strong>￥<span className="is-size-1">{amount}</span>／日</strong>
  </div>
)

// 料金はお問合せの場合
const AmountNothing = () => (
  <div style={{ margin: "1rem -1em 2rem" }}>
    <p className="is-size-5-tablet has-text-weight-bold"><span className="is-size-7"></span>お問い合わせください</p>
  </div>
)

const AmountLabel = ({ planType, children }) => (
  // planType: cloudPreview, edgeHighend
  <div className={`${Styles.amountLabel} ${Styles[planType]}`}>
    <p className="is-size-7 has-text-white has-text-weight-bold">{children}</p>
  </div>
)

const InitCost = ({ children }) => <p className="is-size-7-mobile has-text-weight-bold" style={{ letterSpacing: ".15em", marginBottom: "1.5rem" }}>{children}</p>

const Spec = ({ title, children }) => (
  <div className={Styles.spec}>
    <div className={`is-size-7 ${Styles.title}`}>{title}</div>
    <div className={`has-text-weight-bold ${Styles.body}`}>{children}</div>
  </div>
)

const ComingSoon = () => (
  <div className={`box is-shadowless has-text-grey has-text-centered has-text-weight-bold is-flex ${Styles.comingsoon}`}>
    <p className="is-size-5">coming soon!</p>
    <p className="is-size-7">ただいま<br />準備中です</p>
  </div>
)

//////////////////// sections ////////////////////

const Menu = () => (
  <div className="columns">
    <div className="column is-two-fifth">
      <MenuItem type="cloud" />
    </div>
    <div className="column is-two-fifth">
      <MenuItem type="edge" />
    </div>
    <div className="column is-one-fifth">
      <div className={`is-relative ${Styles.aboutUnitLabel}`}>
        <p className="is-size-7">※クラウド版とエッジ版の違いについては<Link to="/product/unit#unit_feature_cloud">こちら</Link>をご覧ください</p>
      </div>
    </div>
  </div>
)

const CloudPlan = () => (
  <FeatureBox
    title="クラウド版"
    titleIcon="fas fa-cloud"
    buttonLabel="プランを申し込む"
    buttonTo="https://console.natade-coco.com"
  >
    <PlanItem>
      <PlanHeader planName="プレビュー" copy="ベータ版でまずはお試し！" color="#999" />
      <PlanBody>
        <Amount amount="0" />
        <AmountLabel planType="cloudPreview">最長２４時間の短期利用のみ</AmountLabel>
        <InitCost>(初期費用ナシ)</InitCost>
        <Spec title="vCPU"><p>1コア</p></Spec>
        <Spec title="メモリ"><p>2GB</p></Spec>
        <Spec title="ディスク"><p>55GB</p></Spec>
        <Spec title="備考">
          <div className="content">
            <ul className="is-size-7 has-text-left" style={{width: "fit-content", margin: "0 auto"}}>
              <li>お申し込みから１日後に自動的に破棄されます</li>
              <li>同時起動数は１ユニットです</li>
              <li>何度でもご利用いただけます</li>
            </ul>
          </div>
        </Spec>
      </PlanBody>
    </PlanItem>
    <PlanItem>
      <PlanHeader planName="ベーシック" copy="小規模店舗向け" color="#2CB8C4" />
      <ComingSoon />
    </PlanItem>
    <PlanItem>
      <PlanHeader planName="スタンダード" copy="中規模店舗向け" color="#17799D" />
      <ComingSoon />
    </PlanItem>
  </FeatureBox>
)

const EdgePlan = () => (
  <FeatureBox
    title="エッジ版"
    titleIcon="fas fa-server"
    buttonLabel="お問い合わせ"
    buttonTo="/contact"
  >
    <PlanItem>
      <PlanHeader planName="ベーシック" copy="小規模店舗向け" color="#DEB817" />
      <ComingSoon />
    </PlanItem>
    <PlanItem>
      <PlanHeader planName="スタンダード" copy="中規模店舗向け" color="#E59516" />
      <ComingSoon />
    </PlanItem>
    <PlanItem>
      <PlanHeader planName="ハイエンド" copy="大規模・複合施設向け" color="#D9630D" />
      <PlanBody>
        <AmountNothing />
        <AmountLabel planType="edgeHighend">複数ユニット集約型</AmountLabel>
        <InitCost>(初期費用あり)</InitCost>
        <Spec title="スペック">
          <div className="is-size-7">
            <Wrap><span>ご要望に応じた</span><span>スペックを</span><span>ご用意する</span><span>カスタマイズ</span><span>プランです。</span></Wrap>
          </div>
        </Spec>
        <Spec title="備考">
          <div className="is-size-7">
            <Wrap><span>まずはお問い合わせ</span><span>ページから</span><span>お見積もりを</span><span>ご依頼ください。</span></Wrap>
          </div>
        </Spec>
      </PlanBody>
    </PlanItem>
  </FeatureBox>
)

//////////////////// export ////////////////////

const Plan = () => (
  <Mainsection title="プランと料金">
    <MainsectionText>
      <p>配信ユニットのタイプやスペックなど、お客様のご希望に応じて最適なプランをお選びいただけます。</p>
    </MainsectionText>
    <Spacer isHalf />
    <Menu />
    <div id="price_cloud" style={{padding: "4rem 0"}}>
      <CloudPlan />
    </div>
    <div id="price_edge" style={{padding: "4rem 0"}}>
      <EdgePlan />
    </div>
  </Mainsection>
)

export default Plan
