import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Caption from "src/components/price/caption"
import Plan from "src/components/price/plan"
import Unit from "src/components/price/unit"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Caption />
      <Unit />
      <Plan />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
