import React from "react"

const IconUnit = ({size: name, type, icon}) => {
  const sizes = [
    {
      name: "is-size-6",
      width: "5rem",
      padding: "1rem",
    },
    {
      name: "is-size-5",
      width: "6.25rem",
      padding: "1.5rem",
    },
    {
      name: "is-size-4",
      width: "7.5rem",
      padding: "2rem",
    },
    {
      name: "is-size-3",
      width: "10rem",
      padding: "3rem",
    },
  ]
  const size = sizes.find(size => size.name === name)

  return (
      <div className="has-background-white" style={{margin: "auto", paddingTop: size.padding, border: "solid 2px #fff", borderRadius: "50%", width: size.width, height: size.width}}>
        <div className="has-text-centered">
          <div className={`icon ${size.name}`}><i className={`${icon} fa-2x`} /></div>
          <div className={size.name}>{type}</div>
        </div>
      </div>
  )
}

export default IconUnit
